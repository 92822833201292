<template>
    <div class="Projectcheck" style="margin-top:20px;">
        <div class="Project_button">
            <el-button type="primary" size="mini">
                <span>导入</span>
                <input id="upfile" type="file" name="upfile" class="import_input" @change="importExcel($event.target)">
            </el-button>
            <el-button :type="this.multipleSelection.length!=0?'primary':'info'" size="mini" @click="exportStu()">批量导出</el-button>
        </div>
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
            style="width: 100%;height:97%"
            stripe
            @selection-change="handleSelectionChange"
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="selection" label="选择" width="55"></el-table-column>
            <el-table-column prop="Itemno" label="项目编号" width="150px"></el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="type" label="项目类型"></el-table-column>
            <el-table-column prop="Redtrip" label="青年红色筑梦之旅项目" width="120px"></el-table-column>
            <el-table-column prop="headName" label="项目负责人"></el-table-column>
            <el-table-column prop="state" label="状态"  width="120px">
                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.state === '不合格' ? 'danger' : 'success'"
                        disable-transitions>{{scope.row.state}}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data () {
        return {
            pageSize:10,
            totalnum:0,
            currentPage:1,
            multipleSelection:[],
            tableData:[],
        }
    },
    methods: {
         // 导入
        importExcel(){

        },
        //批量导出
        exportStu(){

        },
         // 全选按钮
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.Projectcheck .el-table__body-wrapper{
    min-height: calc(100vh - 357px);
}
.Projectcheck .el-table__empty-block{
    min-height: calc(100vh - 357px);
}
</style>